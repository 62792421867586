<template>
  <b-overlay
    variant="white"
    :show="showLoading"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="sm"
  >
    <b-card>
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <b-form
          id="form-payment-step-id"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <b-row>
            <b-col
              sm="6"
              cols="12"
            >
              <validation-provider
                #default="validationContext"
                name="Kode Bank"
                rules="required"
              >
                <b-form-group
                  label="Kode Bank"
                  label-for="bank_code"
                >
                  <v-select
                    id="bank_code"
                    v-model="bankData.bank_code"
                    :options="bankOptions"
                    :clearable="false"
                    placeholder="Kode Bank"
                    label="code"
                    @option:selected="selectedBank"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              sm="6"
              cols="12"
            >
              <validation-provider
                #default="validationContext"
                name="Nama Bank"
                rules="required"
              >
                <b-form-group
                  label="Nama Bank"
                  label-for="name"
                >
                  <b-form-input
                    id="name"
                    v-model="bankData.bank_name"
                    autofocus
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="Nama Bank"
                    :readonly="true"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              sm="6"
              cols="12"
            >
              <validation-provider
                #default="validationContext"
                name="Judul"
                rules="required"
              >
                <b-form-group
                  label="Judul"
                  label-for="title"
                >
                  <b-form-input
                    id="title"
                    v-model="bankData.title"
                    autofocus
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="Judul"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              sm="6"
              cols="12"
            >
              <validation-provider
                #default="validationContext"
                name="Aktif"
                rules="required"
              >
                <b-form-group
                  label="Aktif"
                  label-for="is_active"
                >
                  <b-form-checkbox
                    id="is_active"
                    v-model="bankData.is_active"
                    name="check-button"
                    switch
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-form-group
            label="Details"
            label-size="lg"
            class="mt-2"
          >
            <draggable
              v-model="bankData.details"
              handle=".handle"
              @change="dragHandler"
            >
              <transition-group name="list">
                <div
                  v-for="(detail, idx) in bankData.details"
                  :key="detail.order_number"
                  class="todo"
                >
                  <!-- <span class="handle">&#8597;</span> -->
                  <div
                    class="d-flex align-items-center p-1 mb-1 border w-100 handle"
                  >
                    <span
                      class="mr-1"
                      style="font-size: 18"
                    >&#8597;</span>
                    <div class="w-100">
                      <validation-provider
                        #default="validationContext"
                        :name="`Description ${idx}`"
                        rules="required"
                      >
                        <b-form-group
                          label="Description"
                          :label-for="`Description-${idx}`"
                          class="w-100"
                        >
                          <b-form-textarea
                            :id="`Description-${idx}`"
                            v-model="bankData.details[idx].description"
                            rows="3"
                            autofocus
                            :state="getValidationState(validationContext)"
                            trim
                            placeholder="Description"
                          />

                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </div>
                    <a
                      href="javascript:void(0)"
                      class="mx-2 p-1"
                    ><feather-icon
                      icon="XIcon"
                      style="color: black;"
                      @click="removeList(idx)"
                    /></span>
                    </a></div>
                </div>
              </transition-group>
            </draggable>
          </b-form-group>
          <b-button
            variant="primary"
            @click="addList"
          ><feather-icon
            icon="PlusIcon"
            size="18"
          /></b-button>
          <div class="d-flex w-100 justify-content-end">
            <b-button
              type="submit"
              variant="primary"
              form="payment-step-id"
              @click="onSubmit"
            >
              Save
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-card>
  </b-overlay>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormCheckbox,
  BButton,
  BFormTextarea,
  BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import {
 ref, onUnmounted, computed, onMounted,
} from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import Draggable from 'vuedraggable'
import store from '@/store'
import paymentStepStore from './paymentStepStore'
import usePaymentStep from './usePaymentStep'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    ValidationObserver,
    ValidationProvider,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    vSelect,
    BFormCheckbox,
    Draggable,
    BButton,
    BFormTextarea,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  setup(props, context) {
    const showLoading = ref(false)
    const PAYMENT_STEP_STORE = 'payment-step'
    if (!store.hasModule(PAYMENT_STEP_STORE)) {
      store.registerModule(PAYMENT_STEP_STORE, paymentStepStore)
    }
    onUnmounted(() => {
      store.unregisterModule(PAYMENT_STEP_STORE)
    })
    const {
 doSave, doRemove, getBanks, getPaymentStepDetail, doUpdate,
} = usePaymentStep(store)
    getBanks()
    const blankPageData = {
      bank_code: null,
      title: null,
      bank_name: null,
      order_number: null,
      is_active: false,
      details: [],
    }
    const bankData = ref(JSON.parse(JSON.stringify(blankPageData)))
    const resetpageData = () => {
      bankData.value = JSON.parse(JSON.stringify(blankPageData))
    }
    const { refFormObserver, getValidationState, resetForm } = formValidation(resetpageData)

    const dragHandler = evt => {
     bankData.value.details.splice(evt.newIndex, 0, evt.element)
      bankData.value.details.splice(evt.oldIndex, 1)
      bankData.value.details = bankData.value.details.map((e, idx) => {
        e.order_number = idx + 1
        return e
      })
    }

    const addList = evt => {
      bankData.value.details.push({
        description: null,
        order_number: bankData.value.details.length + 1,
        payment_step_id: null,
        id: null,
      })
    }

    const selectedBank = evt => {
      bankData.value.bank_code = evt.code
      bankData.value.bank_name = evt.name
    }

    const onSubmit = evt => {
      refFormObserver.value.validateWithInfo().then(res => {
        if (res.isValid) {
          showLoading.value = true
          if (context.root.$route.params.id) {
            doUpdate(bankData.value).then(res => {
              bankData.value = res.data
            }).finally(() => {
              showLoading.value = false
            })
          } else {
            doSave(bankData.value).then(res => {
            context.root.$router.go(-1)
          }).finally(() => {
            showLoading.value = false
          })
          }
        }
      })
    }

    const bankOptions = computed(() => store.getters['payment-step/getBanks'])

    const initData = () => {
      if (context.root.$route.params.id) {
      showLoading.value = true
        getPaymentStepDetail(context.root.$route.params.id).then(response => {
          bankData.value = response.data
        }).finally(() => {
          showLoading.value = false
        })
      }
    }

    const removeList = idx => {
      showLoading.value = true
      const detail = bankData.value.details[idx]
      if (detail.id !== null) {
        doRemove(detail.id).then(response => {
          bankData.value.details.splice(idx, 1)
        }).finally(() => {
          showLoading.value = false
        })
      } else {
        bankData.value.details.splice(idx, 1)
        showLoading.value = false
      }
    }

    onMounted(() => {
      initData()
    })
    return {
      refFormObserver,
      onSubmit,
      getValidationState,
      resetForm,
      bankData,
      dragHandler,
      addList,
      removeList,
      bankOptions,
      selectedBank,
      showLoading,
    }
  },
}
</script>

<style>
.list-move {
  transition: 0.5s;
}
</style>
