export default function usePaymentStep(store) {
  const doSave = formData => store.dispatch('payment-step/doSave', formData)
  const getBanks = () => {
    store.dispatch('payment-step/fetchBanks')
  }
  const doRemove = paymentStepId => store.dispatch('payment-step/deletePaymentStepDetail', paymentStepId)
  const getPaymentStepDetail = id => store.dispatch('payment-step/retrievePaymentStep', id)
  const doUpdate = formData => store.dispatch('payment-step/doUpdate', formData)
  return {
    doSave,
    getBanks,
    getPaymentStepDetail,
    doRemove,
    doUpdate,
  }
}
