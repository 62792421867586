import axios from '@axios'
import crypto from 'crypto'
import { Base64 } from 'js-base64'
import CryptoJS from 'crypto-js'
import Vue from 'vue'

const vm = new Vue()

function aesEncrypt(txt) {
  const iv = crypto.randomBytes(8).toString('hex')
  const cipher = CryptoJS.AES.encrypt(
    txt,
    CryptoJS.enc.Utf8.parse('82f2ceed4c503896c8a291e560bd4325'),
    {
      iv: CryptoJS.enc.Utf8.parse(iv),
      mode: CryptoJS.mode.CBC,
    },
  )

  const ret = [cipher.toString(), iv]

  return Base64.encode(ret)
}
export default {
  namespaced: true,
  state: {
    banks: [],
    paymentStepDetail: {},
  },
  getters: {
    getBanks: state => state.banks,
    getPaymentStepDetail: state => state.paymentStepDetail,
  },
  mutations: {
    setBanks(state, data) {
      state.banks = data
    },
    setPaymentStepDetail(state, data) {
      state.paymentStepDetail = data
    },
  },
  actions: {
    setStatus(ctx, { id, status }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`api/auth/calon-agen/${id}/setStatus`, {
            status,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    doSave(ctx, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post('api/auth/payment-step', formData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    doUpdate(ctx, formData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`api/auth/payment-step/${formData.id}`, formData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchBanks(ctx) {
      axios
        .get('api/auth/payment-step-banks')
        .then(response => {
          ctx.commit('setBanks', response.data.data)
        })
    },
    retrievePaymentStep(ctx, paymentStepId) {
      return new Promise((resolve, reject) => {
        axios
          .get(`api/auth/payment-step/${paymentStepId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deletePaymentStep(ctx, paymentStepId) {
      return new Promise((resolve, reject) => {
        axios.delete(`api/auth/payment-step/${paymentStepId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deletePaymentStepDetail(ctx, paymentStepDetailId) {
      return new Promise((resolve, reject) => {
        axios.delete(`api/auth/payment-step-detail/${paymentStepDetailId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
